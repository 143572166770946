<template>
  <div class="h-100">
    <signin-form></signin-form>
  </div>
</template>

<script>
import SigninForm from "../components/SigninForm.vue";

export default {
  components: { SigninForm }
};
</script>

<style></style>
